<template>
  <!-- <span>{{ time }}</span> -->
  <span>
    <span
      ><span class="item">{{ d }}</span
      ><span>天</span></span
    >
    <span
      ><span class="item">{{ h }}</span> <span>时</span></span
    >
    <span
      ><span class="item">{{ m }}</span> <span>分</span>
    </span>
    <span
      ><span class="item">{{ s }} </span><span>秒</span>
    </span>
  </span>
</template>

<script>
export default {
  name: "TimeDown",
  data() {
    return {
      time: "",
      d: "",
      h: "",
      m: "",
      s: "",
      timerSwitch: null,
    };
  },
  props: {
    endDate: String,
    currentTime: String,
  },
  watch: {
    s(newValue) {
        // this.$router.go(0);
        // console.log(newValue, "newValuenewValuenewValue");
        if (this.d == 0 && this.h == 0 && this.m == 0) {
          this.$emit("send", newValue);
        }
    },
  },
  created() {},
  mounted() {
    let endDate = this.endDate;
    console.log(this.endDate, "end");
    this.timerSwitch = setInterval(() => {
      this.timeDown(endDate);
      endDate -= 1000;
    }, 1000);
  },
  destroyed() {
    if (this.timerSwitch) {
      clearInterval(this.timerSwitch);
      this.timerSwitch = null;
    }
  },
  methods: {
    timeDown(endDate) {
      function forMat(d) {
        return d < 10 ? "0" + d : d;
      }
      if (endDate <= 0) {
        if (this.timerSwitch) {
          clearInterval(this.timerSwitch);
          this.timerSwitch = null;
        }
      } else {
        let d = Math.floor(endDate / 1000 / 60 / 60 / 24);
        let h = Math.floor((endDate / 1000 / 60 / 60) % 24);
        let m = Math.floor((endDate / 1000 / 60) % 60);
        let s = Math.floor((endDate / 1000) % 60);
        this.time = `${d > 0 ? forMat(d) + "天" : ""}${forMat(h)}小时${forMat(
          m
        )}分钟${forMat(s)}秒`;
        this.d = d > 0 ? forMat(d) : "0";
        this.h = forMat(h);
        this.m = forMat(m);
        this.s = forMat(s);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  color: white;
  padding: 6px;
  background: #2a8de9;
  margin: 0 5px;
}
</style>
